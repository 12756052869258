<template>
  <div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Өнгө үүсгэх'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="colorData" :rules="colorRules" ref="colorData">
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="name_mon">
                        <el-input v-model="colorData.name_mon" placeholder="Цагаан ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="name_eng">
                      <el-input v-model="colorData.name_eng" placeholder="White ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Өнгө сонгох">
                      <el-color-picker v-model="color" @change="onChangeColor"></el-color-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Сонгосон өнгө" >
                        <div class="image-holder" :style="`background-color:${color};width:80px; heigth:64px`" >
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Өнгөний код" prop="hex_code">
                        <el-input disabled v-model="colorData.hex_code" placeholder="#6BD90B"></el-input>
                      </el-form-item>
                    </el-col>
                    </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="20" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" :disabled="isDisabled" @click="onPressNewColor('colorData')"
                >Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/colorList">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
export default {
  components: {
    customHeader: customHeader
  },
  data () {
    return {
      isDisabled: false,
      loading: false,
      color: '#6BD90B',
      tempImages: [],
      confirmLeaveForm: false,
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      colorData: {
        name_mon: '',
        name_eng: '',
        hex_code: ''
      },
      colorRules: {
        name_mon: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        hex_code: [
          { required: true, message: 'Өнгөө сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    onChangeColor (color) {
      this.colorData.hex_code = color
    },

    onPressNewColor (formName) {
      this.isDisabled = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          services.addColor(this.colorData).then(data => {
            this.isDisabled = true
            this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
            if (data.status === 'success') {
              this.$refs[formName].resetFields()
              this.$router.push({ name: 'colorList' })
            }
          })
        } else {
          return false
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }

}
</script>

<style></style>
